body, html {
    height: 100% !important;
  }
.parallax {
    background-image: url('../media/images/img_parallax.jpeg');
    height: 100%; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .content-div{
    height:1000px;
    background-color:green;
    font-size:36px;
  }

  .parallax-effect {
    height: 100%; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

.no-dec{ text-decoration:none !important; }
.outer-center {
  /* min-height: 500px; */
  position: relative;
}
.inner-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.center_link{
	text-decoration:none;
    color:white;
}

.center_link_outer{
	border:5px black solid;
    border-radius:5px;
    padding:5px;
    background:lightblue;
}

.dropdown-hover:hover .dropdown-menu {
    display: block;
    margin-top: 0; 
 }

 .dropdown-hover>.dropdown-menu{
     border: 0;
 }

  /* border-design */
.border-design-top{
    width: 100%;
    background-color: #124f63;
    height: 50px;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
}

.border-design-bottom{
    width: 100%;
    background-color: #124f63;
    height: 50px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
}

/* Curved design */
.curved{
    background: #124f63;
    color: #fff;
    text-align: center;
}

.curved p{
    font-size: 1rem;
    margin: 0 5rem 0 5rem;
}

.curved h1{
    font-size: 3rem;
    margin: 0;
}

.curved svg{
    display: block;
}

.curved.upper{
    background: #fff;
}








    
  .text-blue{
    color: #004b74;
  }

  .custom-card{
      
      background: transparent;
  }

  /* //Animated Button */
  .btn-animated-1 {
    border-radius: 4px;
    border: 1px solid #f1f1f1;
    background-color: #fff;
    color: #124f63;
    text-align: center;
    padding: 5px;
    width: 200px;
    transition: all 0.5s;
    cursor: pointer;
  }
  
  .btn-animated-1 span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .btn-animated-1 span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .btn-animated-1:hover span {
    padding-right: 25px;
  }
  
  .btn-animated-1:hover span:after {
    opacity: 1;
    right: 0;
  }

  /* //Normal button hover */
  .btn-hover span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .btn-hover span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  
  .btn-hover:hover span {
    padding-right: 25px;
  }
  
  .btn-hover:hover span:after {
    opacity: 1;
    right: 0;
  }

  /* Slide Animation */
  .aboutUs,.contactUs{
    visibility: hidden;
  }
  .slideRight {
    animation-name: slideRight;
    animation-name: slideRight;
    animation-duration: 1s;
    animation-duration: 1s;
    visibility: visible !important;
  }

  .slideLeft {
    animation-name: slideLeft;
    animation-name: slideLeft;
    animation-duration: 1s;
    animation-duration: 1s;
    visibility: visible !important;
  }
  
  @keyframes slideRight {
    0% {
      opacity: 0;
      transform: translateX(-70%);
    } 
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }

  @keyframes slideLeft {
    0% {
      opacity: 0;
      transform: translateX(70%);
    } 
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }